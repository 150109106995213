
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 !important;
  padding: 0 2rem !important;
  background-color: #f5f5f5 !important;
  box-sizing:border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
@media (max-width: 768px) {
  body {
    padding: 0rem !important;
  }
}

*,
*::after,
*::before {
    /* update universal reset */
    
    margin: 0px;
    padding: 0px;
    /* box-sizing changes the box models so tht the borders and paddings are not added to total width and height*/
    box-sizing: inherit;
}
.background-image {
  background-image: linear-gradient(
    to right bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6)),
    url(../src/assets/bg-4.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 75vh;
}

@media (max-width: 768px) {
  .background-image {
    height: 75vh; 
  }
}

@media (max-width: 576px) {
  .background-image {
    height: 65vh; 
  }
}

@media (max-width: 840px) {
  .objective-sizing {
    width: 100%;
  }
  .backgound-sizing {
    padding: 0;
  }
  .backgound-sizing-leading {
    line-height: 1;
  }
  .backgound-sizing-title {
    font-size: 3rem;
  }
}

@media (max-width: 656px) {
  .backgound-sizing-title {
    font-size: 2rem;
  }
}
@media (max-width: 431px) {
  .backgound-sizing-title {
    font-size: 1.5rem;
  }
  .backgound-sizing-body {
    font-size: 1rem;
  }
  .company-img {
    height: 50vh;
  }
  .service-container {
    padding: 0 0 2rem 0;
  }
}
